import React from 'react';
import 'font-awesome/css/font-awesome.min.css';

const SocialIcons = () => {
  return (
    <div className="social-icons">
      <a href="https://twitter.com/BCMCollective" target="_blank" rel="noopener noreferrer" className="icon">
        <i className="fa fa-twitter"></i>
      </a>
      <a href="https://www.instagram.com/BCMusicCollective/" target="_blank" rel="noopener noreferrer" className="icon">
        <i className="fa fa-instagram"></i>
      </a>
    </div>
  );
};

export default SocialIcons;