import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
    apiKey: "AIzaSyBPqobw2mwW7VjeZ4h1ghnsxsxg1Qj3JGI",
    authDomain: "bcmc-ee10b.firebaseapp.com",
    projectId: "bcmc-ee10b",
    storageBucket: "bcmc-ee10b.appspot.com",
    messagingSenderId: "812167683822",
    appId: "1:812167683822:web:1ff16d4a5144533daf9dff"
  };

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

export { db };