import React, { useState, useEffect, useRef, useCallback } from 'react';
import { MemoryRouter as Router, Routes, Route } from 'react-router-dom';
import HeadBar from './HeadBar';
import SocialIcons from './SocialIcons';
import styled from 'styled-components';
import ErrorBoundary from './ErrorBoundary';
import { useNavigate } from 'react-router-dom';
import { db } from "./firebase";  // Import db from firebaseConfig.js
import { collection, getDocs } from "firebase/firestore";
import { throttle } from 'lodash';
import './App.scss';
import './ShootingStars.scss';

const ArtistProfile = React.lazy(() => import('./ArtistProfile'));
const ArtistsList = React.lazy(() => import('./ArtistsList'));

const ShootingStars = () => {
    const stars = React.useMemo(() => {
        return Array.from({ length: 100 }, (_, i) => ({
            angle: Math.random() * 2 * Math.PI,
            distance: Math.random() * 500 + 500,
            duration: Math.random() * 3 + 2,
            startX: Math.random() * 100,
        }));
    }, []);

    return (
        <div className="shooting-stars">
            {stars.map((star, index) => (
                <div
                    key={index}
                    className="star"
                    style={{
                        '--angle': `${star.angle}rad`,
                        '--distance': `${star.distance}px`,
                        '--duration': `${star.duration}s`,
                        '--startX': `${star.startX}%`,
                    }}
                />
            ))}
        </div>
    );
};

function InnerComponent({ artists }) {  // Add artists as a prop
    const navigate = useNavigate();
    const [selectedArtist, setSelectedArtist] = useState(null);

    useEffect(() => {
        console.log("InnerComponent has been mounted");
        return () => console.log("InnerComponent will unmount");
    }, []);

    const handleArtistClick = (artist) => {
        console.log(`Artist ${artist.name} was clicked`);
        setSelectedArtist(artist);
        navigate(`/artist/${artist.name}`);  // Navigate to artist's profile page
    };

    const handleClose = () => {
        console.log("Closing artist profile");
        setSelectedArtist(null);
    };

    useEffect(() => {
        if (!selectedArtist) {
            navigate('/');
        }
    }, [selectedArtist, navigate]);

    return (
        <Routes>
            <Route path="/artist/:name" element={selectedArtist ? <ArtistProfile artist={selectedArtist} onClose={handleClose} /> : <div>Loading...</div>} />
            <Route path="/" element={<ArtistsList artists={artists} onArtistClick={handleArtistClick} />} />
        </Routes>
    );
}

const MemoizedInnerComponent = React.memo(InnerComponent);

function App() {
    const [translation, setTranslation] = useState({ x: 0, y: 0 });
    const [artists, setArtists] = useState([]);
    const [isInnerComponentVisible, setIsInnerComponentVisible] = useState(true);

    const fetchArtists = React.useCallback(async () => {
        try {
            const querySnapshot = await getDocs(collection(db, "artists"));
            const artistsData = querySnapshot.docs.map(doc => ({
                id: doc.id,
                ...doc.data()
            }));
            setArtists(artistsData);
        } catch (error) {
            console.error("Error fetching artists: ", error);
        }
    }, []);

    const handleMouseMove = useCallback(
        throttle((e) => {
            const centerX = window.innerWidth / 2;
            const centerY = window.innerHeight / 2;
            const offsetX = (e.clientX - centerX) / centerX;
            const offsetY = (e.clientY - centerY) / centerY;
            setTranslation({ x: offsetX, y: offsetY });
        }, 16),  // ~60fps
        []
    );

    const handleDeviceOrientation = useCallback(
        throttle((e) => {
            const { beta, gamma } = e;
            const offsetX = gamma / 45;
            const offsetY = beta / 45;
            setTranslation({ x: offsetX, y: offsetY });
        }, 16),  // ~60fps
        []
    );

    useEffect(() => {
        // Request permission for iOS 13+
        if (typeof DeviceOrientationEvent.requestPermission === 'function') {
            DeviceOrientationEvent.requestPermission()
                .then(permissionState => {
                    if (permissionState === 'granted') {
                        window.addEventListener('deviceorientation', handleDeviceOrientation);
                    }
                })
                .catch(console.error);
        } else {
            // Non-iOS 13+
            window.addEventListener('deviceorientation', handleDeviceOrientation);
        }

        // Add mouse move event listener for desktop
        window.addEventListener('mousemove', handleMouseMove);

        return () => {
            window.removeEventListener('mousemove', handleMouseMove);
            window.removeEventListener('deviceorientation', handleDeviceOrientation);
        };
    }, []);

    useEffect(() => {
        fetchArtists();
    }, []);

    return (
        <ErrorBoundary>
            <Router>
                <div className="App" onMouseMove={handleMouseMove}>
                    <div
                        className="main-content-back"
                        style={{
                            '--translate-x': `${translation.x}%`,
                            '--translate-y': `${translation.y}%`
                        }}
                    />
                    <ShootingStars />
                    <a href="https://BCMC.world/apparel/" target="_blank" rel="noopener noreferrer" className="icon">
                        <div className='sto' />
                    </a>
                    <SocialIcons />
                </div>
                <div className='center-container'>
                    <div className='main-artists'>
                        <div>
                            <HeadBar />
                        </div>
                        <React.Suspense fallback={<div>Loading...</div>}>
                            {isInnerComponentVisible && <MemoizedInnerComponent artists={artists} />}
                        </React.Suspense>
                    </div>
                    {/* Toggle switch */}
                    <div className="toggle-switch">
                        <label>
                            <input type="checkbox" checked={isInnerComponentVisible} onChange={() => setIsInnerComponentVisible(!isInnerComponentVisible)} />

                        </label>
                    </div>
                </div>
            </Router>
        </ErrorBoundary>
    );
}

export default App;