import React from 'react';
import styled from 'styled-components';
import ErrorBoundary from './ErrorBoundary';

const StyledHeadBar = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding-top: 1rem;
    animation: fadeIn 0.8s ease-in;
    width: 100%;
    z-index: 3;

    @keyframes fadeIn { 
        0% { opacity: 0;
             top: -300px; }
        100% { opacity: 1;
               top: 0px; }
    }
`;

const HeadBar = ({ }) => {

    return (
        <ErrorBoundary>
            <StyledHeadBar>
            <div className='head-img' />
            </StyledHeadBar>
        </ErrorBoundary>
    );
};

export default HeadBar;